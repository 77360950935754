<template>
  <div class="main_container">
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <router-link to="/decompte">
          Facture >
        </router-link>
        <span>Détails de la facture ></span>
      </div>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Détails de la facture
          </div>
        </div>
      </div>
    </div>
  
    <div class="inner_container">
      <div class="row action_bar2">

        <div class="col-auto">
          <button type="button"
                  class="btn btn-f-blue "
                  @click="goBack()">&nbsp; &#x21E6; Retour
          </button>
        </div>

        <div class="col-auto">
          <button type="button"
                  class="btn btn-f-blue"
                  v-if="assignDecompte.statut =='DEMANDE_INFORMATION'"
                  @click="updateDecompte()">
            &#9998; Modifier
          </button>

        </div>

        <div class="col-auto"
             v-if=" assignDecompte.dernier_valideur ===null && assignDecompte.action_dernier_valideur ==='EN_ATTENTE' ">
          <button type="button"
                  class="btn btn-f-blue mx-1"
                  data-toggle="modal"
                  data-target="#suppressionModal">
            &#10060; Supprimer
          </button>
        </div>


        

        <!-- 
        <div class="col-auto">
          <a class="btn btn-grn mb-3">Clôturer</a>
        </div>
        -->
      </div>
    </div>

    <div class="col-auto">
      <template v-if="assignDecompte.statut_final === 'VALIDE'">
        <div class="title_alert">
          <label class="alert_success">Status : {{ assignDecompte.statut_final }} </label>
        </div>
      </template>

      <template v-else-if="assignDecompte.statut_final === 'EN_ATTENTE'">
        <div class="title_alert">
          <label class="alert_danger"
                 v-if="assignDecompte.action_dernier_valideur ==='DEMANDE_INFORMATION'">
            Status : Demande information
          </label>
          <label class="alert_danger"
                 v-else>
            Status : En attente de validation
          </label>
          <button v-if="viewTabCommentaire === 'false' && gettableauCommentaires.donnees.length > 1"
                  class="btn btn-link"
                  type="button"
                  @click="displayTabCommentaire()">Voir l'historique</button>
          <button v-if="viewTabCommentaire === 'true'"
                  class="btn btn-link"
                  type="button"
                  @click="displayNoneTabCommentaire()">Cacher l'historique</button>
        </div>
        <div class="info_tab container"
             v-if="gettableauCommentaires.donnees[0] !== undefined && viewTabCommentaire === 'true'">
          <h5>Demande d'informations</h5>
          <table class="table table-hover">
            <thead>
              <tr scope="col"
                  class="bg-black">
                <th>Origine</th>
                <th>Email</th>
                <th>Contenu</th>
                <th>Date de création</th>
              </tr>
            </thead>
            <tbody v-if="viewTabCommentaire === 'true'">
              <tr scope="row"
                  v-for="(commentaire, commetaireKey) in gettableauCommentaires.donnees"
                  :key="commetaireKey">
                <td>{{ commentaire.utilisateur.type_utilisateur }}</td>
                <td>{{ commentaire.utilisateur.email }}</td>
                <td>{{ commentaire.text }}</td>
                <td>{{ createDateFromString(commentaire.created_at) }}</td>
              </tr>
            </tbody>
            <tbody v-if="viewTabCommentaire === 'false'">
              <tr scope="row"
                  class="bg-warning">
                <td>{{ gettableauCommentaires.donnees[0].utilisateur.type_utilisateur }}</td>
                <td>{{ gettableauCommentaires.donnees[0].utilisateur.email }}</td>
                <td>{{ gettableauCommentaires.donnees[0].text }}</td>
                <td>{{ createDateFromString(gettableauCommentaires.donnees[0].created_at) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>

      <template v-else>
        <div class="title_alert">
          <label class="alert_yellow">Status : {{ assignDecompte.statut_final }} </label>
        </div>
      </template>
    </div>

    <div class="info_tab container">
      <div class="row mt-3">
        <table class="table col-md-10 mx-auto"
               style="font-size: 0.6em;">
          <thead>
            <tr class="bg-black">
              <th>Informations de la facture</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">Numéro de la facture</td>
              <td>{{ assignDecompte.numero_decompte }}</td>
            </tr>
            <tr>
              <td scope="row">Montant de la facture</td>
              <td>{{ Math.round(assignDecompte.montant_facture_decompte).toLocaleString() }} FCFA</td>
            </tr>
            <tr>
              <td scope="row">Date facture</td>
              <td>{{ assignDecompte.date_facture }}</td>
            </tr>
            
            <tr>
              <td scope="row">Référence du courrier d'appel de fonds</td>
              <td>{{ assignDecompte.reference_courrier_appel_fonds }}</td>
            </tr>
            <tr>
              <td scope="row">Référence Courrier d’indication de la ligne supportant la dépense</td>
              <td>{{ assignDecompte.reference_courrier_indication_ligne_lcd }}</td>
            </tr>
            <tr>
              <td scope="row">Référence Courrier de demande de proposition de prix</td>
              <td>{{ assignDecompte.reference_demande_proposition_prix }}</td>
            </tr>
            <tr>
              <td scope="row">Référence Courrier d’invitation à une séance de négociation</td>
              <td>{{ assignDecompte.reference_courrier_invitation_sn }}</td>
            </tr>
            <tr>
              <td scope="row">Référence courrier de confirmation de prix</td>
              <td>{{ assignDecompte.reference_confirmation_prix }}</td>
            </tr>
            <tr>
              <td scope="row">Référence demande de cotation</td>
              <td>{{ assignDecompte.reference_formulaire_demande_cotation }}</td>
            </tr>
            <tr>
              <td scope="row">Référence formulaire de selection</td>
              <td>{{ assignDecompte.reference_formulaire_selection }}</td>
            </tr>
            <tr>
              <td scope="row">Référence certificat de service fait</td>
              <td>{{ assignDecompte.reference_certificat_fait }}</td>
            </tr>
            <tr>
              <td scope="row">Référence du certificat pour paiement</td>
              <td>{{ assignDecompte.reference_certificat_paiment }}</td>
            </tr>
            <tr>
              <td scope="row">Référence ordre de mission</td>
              <td>{{ assignDecompte.reference_ordre_mission }}</td>
            </tr>
            <tr>
              <td scope="row">Référence lettre d'invitation à la formation</td>
              <td>{{ assignDecompte.reference_lettre_invitation_formation }}</td>
            </tr>
            <tr>
              <td scope="row">Référence Facture</td>
              <td>{{ assignDecompte.reference_facture }}</td>
            </tr>
            <tr>
              <td scope="row">Date du certificat de partivipation</td>
              <td>{{ assignDecompte.date_certificat_participation }}</td>
            </tr>
            <tr>
              <td scope="row">Date du certificat de paiement</td>
              <td>{{ assignDecompte.date_certificat_paiement }}</td>
            </tr>
            <tr>
              <td scope="row">Date du certificat de service fait</td>
              <td>{{ assignDecompte.date_certificat_service_fait }}</td>
            </tr>
            <tr>
              <td scope="row">Date du courrier d'appel de fonds</td>
              <td>{{ assignDecompte.date_courrier_appel_fond }}</td>
            </tr>
            <tr>
              <td scope="row">Date de la demande de proposition de prix </td>
              <td>{{ assignDecompte.date_demande_proposition_prix }}</td>
            </tr>
            <tr>
              <td scope="row">Date du courrier d'invitation à une séance de négotiation </td>
              <td>{{ assignDecompte.date_courrier_invitation_sn }}</td>
            </tr>
            <tr>
              <td scope="row">Date du courrier de confirmation de prix </td>
              <td>{{ assignDecompte.date_courrier_confirmation_prix }}</td>
            </tr>
            <tr>
              <td scope="row">Date de l'ordre de mission </td>
              <td>{{ assignDecompte.date_ordre_mission }}</td>
            </tr>
            <tr>
              <td scope="row">Date Facture </td>
              <td>{{ assignDecompte.date_facture }}</td>
            </tr>
            <tr>
              <td scope="row">Date du formulaire de demande de cotation </td>
              <td>{{ assignDecompte.date_formulaire_demande_cotation }}</td>
            </tr>
            <tr>
              <td scope="row">Date du formulaire de selection </td>
              <td>{{ assignDecompte.date_formulaire_selection }}</td>
            </tr>
            <tr>
              <td scope="row">Status</td>
              <td>
                <span v-if="assignDecompte.statut_final === 'EN_ATTENTE'"
                      class="badge badge-pill badge-warning">
                  En attente
                </span>
                <span v-if="assignDecompte.statut_final === 'VALIDE'"
                      class="badge badge-pill badge-success">
                  Validé
                </span>
                <span v-if="assignDecompte.statut_final === 'DEMANDE_INFORMATION'"
                      class="badge badge-pill badge-danger">
                  Rejeté
                </span>
                <button v-if="viewTabCommentaire === 'false'"
                        class="ml-2 btn btn-link"
                        type="button"
                        @click="displayTabCommentaire()">Voir les commentaires</button>
                <button v-if="viewTabCommentaire === 'true'"
                        class="ml-2 btn btn-link"
                        type="button"
                        @click="displayNoneTabCommentaire()">Cacher les commentaires</button>
              </td>
            </tr>
          </tbody>
          <thead>
            <tr class="bg-black">
              <th>Source de la facture</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">Activité</td>
              <td>{{ assignDecompte.activite.libelle }}</td>
            </tr>
            <tr>
              <td scope="row">Marché</td>
              <td>{{ assignDecompte.marche.numero_marche }}</td>
            </tr>
          </tbody>
          <thead>
            <tr class="bg-black">
              <th>Rapport</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="assignDecompte.rapports.length>0">
            <tr v-for="(rapport, rapkey) in assignDecompte.rapports"
                :key="rapkey">
              <td scope="row">Rapport n°{{ rapkey }} du {{ rapport.date_rapport }}</td>
              <td>
                <a :href="rapport.rapport"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td scope="row"
                  colspan="2"
                  class="text-center">Aucun rapport trouvé</td>
            </tr>
          </tbody>
          <thead>
            <tr class="bg-black">
              <th style="width:40%">Fichier à télécharger</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">Bon de livraison</td>
              <td>
                <a :href="assignDecompte.bon_livraison"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Certificat de service fait</td>
              <td>
                <a :href="assignDecompte.certificat_service_fait"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Certificat de paiement</td>
              <td>
                <a :href="assignDecompte.certificat_paiement"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Certificat de participation</td>
              <td>
                <a :href="assignDecompte.certificat_participation"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Facture</td>
              <td>
                <a :href="assignDecompte.facture"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Situation financière du marché</td>
              <td>
                <a :href="assignDecompte.situation_financiere_marche"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Courrier de confirmation de prix</td>
              <td>
                <a :href="assignDecompte.courrier_confirmation_prix"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Courrier d'identification de ligne lcd</td>
              <td>
                <a :href="assignDecompte.courrier_indication_ligne_lcd"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Courrier d'invitation sn</td>
              <td>
                <a :href="assignDecompte.courrier_invitation_sn"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Demande de proposition de prix</td>
              <td>
                <a :href="assignDecompte.demande_proposition_prix"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Decompte</td>
              <td>
                <a :href="assignDecompte.decompte"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Pièce justificative des dépenses effectuées sur l'appel de fonds précédent</td>
              <td>
                <a :href="assignDecompte.piece_justificative_depenses_effectuees_appel_fond_precedent"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Piece justificative des dépenses engagées pendant la formation</td>
              <td>
                <a :href="assignDecompte.piece_justificative_depenses_engagees_pendant_formation"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Tableau récapitulatif des dépenses effectuées lors de la demande précédente</td>
              <td>
                <a :href="assignDecompte.tableau_recap_depenses_effectuees_demande_precedente"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Ordre de mission</td>
              <td>
                <a :href="assignDecompte.ordre_mission"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Courrier d'appel de fonds</td>
              <td>
                <a :href="assignDecompte.courrier_appel_fonds"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Formulaire de selection</td>
              <td>
                <a :href="assignDecompte.formulaire_selection"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
            <tr>
              <td scope="row">Formulaire de demande de cotation</td>
              <td>
                <a :href="assignDecompte.formulaire_demande_cotation"
                   target="_blank"
                   download>Télécharger
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div class="row">
        <div class="col-md-4">
          <div class="grp_secondary">
            <h4>Numéro décompte</h4>
            <label>{{ assignDecompte.numero_decompte }}</label>
          </div>
        </div>
        <div class="col-md-4 offset-md-4">
          <div class="grp_secondary">
            <h4>Prestataire</h4>
            <label v-if="assignDecompte.sous_traitant !== undefined">{{ assignDecompte.sous_traitant.libelle }}</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="grp_secondary">
            <h4>Reference de lettre de commande</h4>
            <label>{{ assignDecompte.reference_lettre_commande }}</label>
          </div>
        </div>
        <div class="col-md-4 offset-md-4">
          <div class="grp_secondary">
            <h4>date de lettre de commande</h4>
            <label>{{ assignDecompte.date_lettre_commande }}</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="grp_secondary">
            <h4>Type d’entretien</h4>
            <label>{{ assignDecompte.type_entretien }}</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="grp_secondary">
            <h4>Date de démarrage effectif</h4>
            <label>{{ assignDecompte.date_demarrage_effectif }}</label>
          </div>
        </div>
        <div class="col-md-4 offset-md-4">
          <div class="grp_secondary">
            <h4>Date d'ordre de service</h4>
            <label>{{ assignDecompte.date_ordre_service }}</label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="grp_secondary">
            <h4>Référence de la facture</h4>
            <label>{{ assignDecompte.reference_facture }}</label>
          </div>
        </div>
        <div class="col-md-4 offset-md-4">
          <div class="grp_secondary">
            <h4>Montant de la facture/décompte</h4>
            <label v-if="assignDecompte.montant_facture_decompte !== undefined">{{ assignDecompte.montant_facture_decompte.toLocaleString() }}</label>
          </div>
        </div>
      </div>
      <template >
        <div class="row row_mid">
          <div class="col-md-4">
            <div class="grp_secondary">
              <h4>Bon livraison</h4>
              <label><a :href="assignDecompte.bon_livraison"
                        download>Telecharger</a></label>
            </div>
          </div>
          <div class="col-md-4 offset-md-4">
            <div class="grp_secondary">
              <h4>Certificat de service fait</h4>
              <label><a :href="assignDecompte.certificat_service_fait"
                        download>Telecharger</a></label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="grp_secondary">
              <h4>Courrier de confirmation de prix</h4>
              <label><a :href="assignDecompte.courrier_confirmation_prix"
                        download>Telecharger</a></label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="grp_secondary">
              <h4>Courrier d'indication en ligne LCD</h4>
              <label><a :href="assignDecompte.courrier_indication_ligne_lcd"
                        download>Telecharger</a></label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="grp_secondary">
              <h4>Courrier d'invitation SN</h4>
              <label><a :href="assignDecompte.courrier_invitation_sn"
                        download>Telecharger</a></label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="grp_secondary">
              <h4>Facture</h4>
              <label><a :href="assignDecompte.facture"
                        download>Telecharger</a></label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="grp_secondary">
              <h4>Situation financière du marché</h4>
              <label><a :href="assignDecompte.situation_financiere_marche"
                        download>Telecharger</a></label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="grp_secondary">
              <h4>Demande de proposition de prix</h4>
              <label><a :href="assignDecompte.demande_proposition_prix"
                        download>Telecharger</a></label>
            </div>
          </div>
        </div>
      </template> -->
      <!-- decompte intellectuel -->
      <!-- <template v-else>
        <div class="row row_mid">
          <div class="col-md-4">
            <div class="grp_secondary">
              <h4>Attachement</h4>
              <label><a :href="assignDecompte.attachement"
                        download>Telecharger</a></label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="grp_secondary">
              <h4>Certificat de service fait</h4>
              <label><a :href="assignDecompte.certificat_service_fait"
                        download>Telecharger</a></label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="grp_secondary">
              <h4>Décompte</h4>
              <label><a :href="assignDecompte.decompte"
                        download>Telecharger</a></label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="grp_secondary">
              <h4>Facture</h4>
              <label><a :href="assignDecompte.facture"
                        download>Telecharger</a></label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="grp_secondary">
              <h4>Situation financière du marché</h4>
              <label><a :href="assignDecompte.situation_financiere_marche"
                        download>Telecharger</a></label>
            </div>
          </div>
          <div class="col-md-4">
            <div class="grp_secondary">
              <h4>Rapport</h4>
              <label><a :href="assignDecompte.rapport"
                        download>Telecharger</a></label>
            </div>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="col-md-12 ">
          <div class="grp_secondary">
            <h4>Observation</h4>
            <label>
              {{ assignDecompte.observation }}
            </label>
          </div>
        </div>
      </div> -->
    </div>
    <!-- MOdal -->
    <div class="modal fade"
         id="exampleModal2"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header ">
            <h5 class="modal-title text-uppercase"
                id="exampleModalLabel">Demande d'informations</h5>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p v-if="assignDecompte.demande_information"
               class="mt-2">{{ assignDecompte.demande_information }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade"
         id="suppressionModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="suppressionModalLabel"
         aria-hidden="true">

      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title"
                id="suppressionModalLabel">Suppression de decompte</h4>
            <button type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <p class="text-danger">Voulez vous vraiment supprimer ce decompte ?</p>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-success"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    @click="SupprimerDecompteDGIR(assignDecompte.numero_decompte)"
                    class="btn btn-danger">Supprimer</button>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>
<style>
@import "./decompte.css";
.upload_input::after {
  left: 0;
  width: 88px;
  content: "Fichier..." !important;
  font-size: 16px;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  line-height: 30px;
  cursor: pointer;
}
</style>
<script>
import Notif from "@/components/shared/Toast"
import { mapActions, mapGetters } from "vuex"
export default {
  name: "DetailDecompte",
  components:{
    Notif
  },
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    programmeData: {
      limite: 10,
      avant: "MQ=="
    },
    viewTabCommentaire: "false",
    status_valider:"false"
  }),
  watch: {
    assignDecompte(){
      if(this.assignDecompte.dernier_valideur === "FER_DT" && this.assignDecompte.action_dernier_valideur ==="DEMANDE_INFORMATION" ){
        this.status_valider="true"
      }
    }
  },
  computed: {
    ...mapGetters(["decompterrors", "successdecompte", "marches", "files", "prestataires", "assignDecompte", "gettableauCommentaires","getEtatSuppressionDecompte"])
  },
  created() {
    if (this.$route.params.id) {
      this.getDecompte(this.$route.params.id)
    }
    else {
      this.noData = "No record"
    }
    this.actionGetCommentaires(this.$route.params.id)
  },
  methods: {
    ...mapActions(["saveDecomptes", "Marches", "saveFile", "getPrestataires", "getDecompte", "actionGetCommentaires","actionSuppresionDecompte"]),

    createDateFromString(chaine) {
      const tab = chaine.split(":")
      const dateheure = tab[0].split("T")
      const date = dateheure[0]
      const heureminute = dateheure[1] + "h" + tab[1]
      //var seconde = tab[2].split(".")[0]
      return date + " à " + heureminute
    },
    displayTabCommentaire() {
      this.viewTabCommentaire = "true"
    },
    displayNoneTabCommentaire() {
      this.viewTabCommentaire = "false"
    },
    /**
     * Pour la suppression d'un decompte dont l'id est donnée en argments
     * @param decompteId
     * @constructor
     */
    SupprimerDecompteDGIR(decompteId){

      try {
        this.actionSuppresionDecompte(this.$route.params.id)
        this.notif.message = "Le decompte avec numero = "+decompteId+" a été bien supprimé"
        this.notif.type = "toast-success"
        this.notif.activated = "activated"

        setTimeout(
          function() {
            this.notif.activated = ""
            this.$router.push({name:"decompte"})
            location.reload()
          }.bind(this),3000)
      }catch (exception){

        this.notif.message = "Le decompte avec numero = "+decompteId+" n'a pas été supprimé"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"

        setTimeout(
          function() {
            this.notif.activated = ""
            location.reload()
          }.bind(this),3000)
      }
      this.notif.message = "Le decompte avec id = "+decompteId+" a été bien supprimé"
      this.notif.type = "toast-success"
      this.notif.activated = "activated"

      setTimeout(
        function() {
          this.notif.activated = ""
          this.$router.push({name:"decompte"})
          location.reload()
        }.bind(this),3000)
    },
    /**
     * Retour en arriere
     */
    goBack() {
      this.$router.push({ name: "decompte" })
    },
    updateDecompte(){
      this.$router.push({name:"ModifyDecompte",params: {id:this.$route.params.id}})
    }
  }
}
</script>
